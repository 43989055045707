import React from "react";

import "../../Styles/Homepage/threeMonths.css";

const StPatricksDayPromo = () => {
    const goToCart = (productId) => {
    
        window.open(
          `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
          "_self"
        );
      };
  return (
    <div className="promo-container">
          <div className="promoCont">
          <div className="promo-header">Exciting Update</div>
      <h1 className="promo-title">
        St. Patrick’s Day Celebrations Start NOW!
      </h1>
      <button className="promo-button"  onClick={() => {
         window.location.href.includes("cw") ?
                goToCart(328515) :  goToCart(284001)
                }}>CLICK HERE TO GET STARTED</button>
      <p className="promo-text">
        Sign up for 3 months of MyIrelandBox membership and receive:
      </p>
      <ul className="promo-list">
        <li>🍀 February’s MyIrelandBox (ships next!)</li>
        <li>
          🍀 <strong>Guaranteed March St. Patrick’s Day Box</strong> (an
          all-time favorite!)
        </li>
        <li>🍀 April’s cozy Irish Farmhouse Box</li>
      </ul>
      <p className="promo-bonus">
        As a Bonus Your first MyIrelandBox will also include a{" "}
        <span className="highlight">FREE $70 Claddagh Necklace</span>
      </p>
          </div>
    </div>
  );
};

export default StPatricksDayPromo;
